<template>
  <div
    class="modal card"
    style="z-index: 1006 !important"
    v-bind:class="{ 'is-active': showExportModal }"
  >
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body notification">
        <button class="delete" @click="closeModal">close</button>
        <h3 class="title is-6 has-text-success">
          Choose region to export data
        </h3>
        <div class="level">
          <div class="level-left">
            <div class="select is-small is-rounded mx-3">
              <select v-model="selectedExportingProvince">
                <option
                  v-for="option in regionsExportList"
                  :selected="option == selectedExportingProvince"
                  :key="option"
                  :value="option"
                  class="is-size-7 has-text-weight-semibold has-text-success"
                >
                  {{ regionToName[option] }}
                </option>
              </select>
            </div>
          </div>
          <button
            v-bind:class="
              exportingInProgress
                ? 'level-item mx-3 button is-small is-warning is-rounded is-loading'
                : 'level-item mx-3 button is-small is-warning is-rounded'
            "
            @click="exportPdf"
          >
            Export File
          </button>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { saveAs } from "file-saver";
import { getPostalCodeByRegion } from "../store/mongoStore";

export default {
  data() {
    return {
      selectedExportingProvince: "",
      regionsExportList: [
        "AB",
        "BC",
        "MB",
        "NB",
        "NS",
        "ON",
        "PE",
        "QC",
        "SK",
        "NL",
      ],
      regionToName: {
        AB: "Alberta",
        BC: "British Columbia",
        MB: "Manitoba",
        NB: "New Brunswick",
        NL: "Newfoundland and Labrador",
        NS: "Nova Scotia",
        NT: "Northwest Territories",
        NU: "Nunavut",
        ON: "Ontario",
        PE: "Prince Edward Island",
        QC: "Quebec",
        SK: "Saskatchewan",
        YT: "Yukon",
      },
      exportingInProgress: false
    };
  },
  props: {
    showExportModal: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.exportingInProgress = false;
      this.$emit("closeModal");
    },
    /**
     * Export PDF of Postal Codes Data
     */
    async exportPdf() {
      if (this.selectedExportingProvince === "") {
        this.$notify({
          type: "warn",
          group: "export",
          title: "No selected provice",
          text: "Please select a region",
          duration: 20000,
        });
        return;
      }
      const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      };
      try {
        this.exportingInProgress = true;
        // const currentRegion = this.$store.state.region;
        this.showChangingRegionModal = true;
        const binary = await getPostalCodeByRegion(
          this.selectedExportingProvince
        );
        const binaryFile = binary.data;
        console.log("binary:", binary);
        const blob = new Blob([s2ab(binaryFile)], {
          type: "application/octet-stream",
        });
        console.log("blob:", blob);
        saveAs(blob, `zones-postalcode.xlsx`);
        this.showChangingRegionModal = false;
        this.exportingInProgress = false;
        this.$notify({
          type: "success",
          group: "zones",
          title: "Export File Success",
        });
        this.showExportModal = false;
        this.$emit("closeModal");
      } catch (err) {
        this.showChangingRegionModal = false;
        this.exportingInProgress = false;
        this.$notify({
          duration: 100000,
          type: "error",
          group: "zones",
          title: "There was an error on exporting",
        });
      }
    },
  },
};
</script>
